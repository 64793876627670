import React from "react";

export default function Hero() {
	return (
		<section className="hero">
			<img className="hero__img" src="https://scrimba.com/blobs/sha1:009c36ec433f5b44e22afa3ab11ef3ca899f5444.png" alt="" />

			<h1 className="hero__header">Online Experiences</h1>
			<p className="hero__text">Join unique interactive activities led by 
			one-of-a-kind hosts—all without leaving home.</p>

		</section>
	)
}