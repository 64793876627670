import React from "react";
import Card from "./Components/Card";
import Hero from "./Components/Hero";
import Navbar from "./Components/Navbar";
import {data} from "./cardData";

export default function App() {

	const cards = data.map((card) => {
		return <Card
					
					/*id={card.id}
					title={card.title}
					description={card.description}
					price={card.price}
					coverImg={card.coverImg}
					rating={card.stats.rating}
					reviewCount={card.stats.reviewCount}
					location={card.location}
					openSpots={card.openSpots} */
					key={card.id}
					card={card}
				/>
	})

	return (
		<>
			<Navbar />
			<Hero />
			<div className="cardContainer">
				{cards}
			</div>
		</>
	)
}