import React from "react";

export default function Card(props) {
	let statusHTML;
	// If openSpots is 0, set statusHTML to the badge as sold out.
	if (props.card.openSpots === 0) {
		statusHTML = statusBadge("SOLD OUT");
	}
	// Else if location is online, set statusHTML to the badge as online.
	else if (props.card.location === "Online") statusHTML = statusBadge("ONLINE");

	// Otherwise, statusHTML is empty (not true), and won't print anything out.

	// Return
	return (
		<div className="card" id={props.card.id}>
			<img className="card__img" src={`https://scrimba.com/blobs/${props.card.coverImg}`} alt={props.card.coverImgAlt}/>
			
			{statusHTML}
			
			<div className="card__details">
				<div className="card__stats">
					<i className="fa fa-star"></i>
					<span>{props.card.stats.rating}</span>
					<span className="grey">({props.card.stats.reviewCount}) • </span>
					<span className="grey">{props.card.location}</span>
				</div>
				<p className="card__title">{props.card.title}</p>
				<p><b>From ${props.card.price}</b> / person</p>
			</div>
		</div>
	)
}
function statusBadge(statusTxt) {
	return (
		<div className="card__status"><h3>{statusTxt}</h3></div>
	)
}